
import { Component, Vue } from 'vue-property-decorator';
import WidgetEmbed from '@/components/widgets/WidgetEmbed.vue';
import { Getter } from 'vuex-class';
import { ConfigValueName, LanguageKey } from '@govflanders/mbp-admin-panel-shared';

import { languageOptions } from '@govflanders/mbp-admin-panel-shared';

@Component({
  components: {
    WidgetEmbed,
  },
})
export default class WidgetPreview extends Vue {
  @Getter('config/get')
  private getConfig: (ConfigValueName) => string;

  public headerId = null;
  public headerUrl = null;

  public footerId = null;
  public footerUrl = null;

  public isCustomLoadingEnabled = false;

  public languageOptions = languageOptions;

  public language: LanguageKey = 'nl';

  public defaultLanguage: LanguageKey = 'nl';

  public languageSelected(lang: LanguageKey) {
    document.documentElement.lang = lang;
  }

  getLabel(language: LanguageKey) {
    const defaultPostfix = this.defaultLanguage === language ? ' (default)' : '';
    return this.$t(`translations.${language}`) + defaultPostfix;
  }

  async fetchDefaultLanguage(widgetId: string) {
    const response = await fetch(this.generateUrl(widgetId)).then(r => r.json());
    this.defaultLanguage = response?.pluginContext?.i18n?.default || 'nl';
  }

  public async created() {
    this.headerId = this.$route.query.header;
    this.footerId = this.$route.query.footer;

    if (this.headerId) {
      this.setHeaderUrl();
    }

    if (this.footerId) {
      this.setFooterUrl();
    }

    if (!this.headerId && !this.footerId) {
      this.isCustomLoadingEnabled = true;
    }

    await this.fetchDefaultLanguage(this.headerId);
    this.language = this.defaultLanguage;
    this.languageSelected(this.language);
  }

  public setHeaderUrl() {
    if (this.headerId !== this.$route.query.header) {
      this.$router.replace({ query: { ...this.$route.query, header: this.headerId } });
    }
    this.headerUrl = this.generateUrl(this.headerId);
  }

  public setFooterUrl() {
    if (this.footerId !== this.$route.query.footer) {
      this.$router.replace({ query: { ...this.$route.query, footer: this.footerId } });
    }
    this.footerUrl = this.generateUrl(this.footerId);
  }

  public sendEmbedErrorNotification() {
    this.$toast.error(this.$t('widget-preview.embed-error'));
  }

  private generateUrl(widgetId) {
    if (widgetId) {
      return `${this.getConfig(ConfigValueName.WIDGET_BASE_URL)}/${widgetId}`;
    }

    return null;
  }
}
